var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('aset-modal', {
    on: {
      "submit": _vm.onChooseBarang
    }
  }), _c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_vm.canApprove ? _c('div', {
    staticClass: "d-flex justify-content-end mb-1"
  }, [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": _vm.perbaikan.gm_approve == 1 ? "outline-secondary" : "outline-success",
      "disabled": _vm.perbaikan && _vm.perbaikan.gm_approve == 1
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.approve(1);
      }
    }
  }, [_vm._v("Setujui Perbaikan")]), _c('b-button', {
    attrs: {
      "variant": _vm.perbaikan.gm_approve == 2 ? "outline-secondary" : "outline-danger",
      "disabled": _vm.perbaikan && (_vm.perbaikan.finance_approve == 1 || _vm.perbaikan.gm_approve == 2)
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.approve(2);
      }
    }
  }, [_vm._v("Tolak Perbaikan")])], 1) : _vm._e(), _vm.canPay ? _c('div', {
    staticClass: "d-flex justify-content-end mb-1"
  }, [_c('b-button', {
    attrs: {
      "variant": [0, 2].includes(_vm.perbaikan.gm_approve) ? 'outline-secondary' : 'outline-primary',
      "disabled": [0, 2].includes(_vm.perbaikan.gm_approve)
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.openFormAkun($event);
      }
    }
  }, [_vm._v("Pilih Sumber Pengeluaran")])], 1) : _vm._e(), _c('b-card-actions', {
    staticStyle: {
      "overflow-x": "auto"
    },
    attrs: {
      "title": "Info Perbaikan",
      "action-collapse": ""
    }
  }, [_c('table', {
    staticClass: "w-full table table-borderless table-responsive",
    staticStyle: {
      "overflow": "auto"
    },
    attrs: {
      "border": "1"
    }
  }, [_c('tr', [_c('td', [_vm._v("No. Perbaikan")]), _c('th', [_vm._v(_vm._s(_vm.perbaikan ? _vm.perbaikan.no : null))]), _c('td', [_vm._v("Judul")]), _c('th', [_vm._v(_vm._s(_vm.perbaikan ? _vm.perbaikan.judul : null))])]), _c('tr', [_c('td', [_vm._v("Tanggal Perbaikan")]), _c('th', [_vm._v(_vm._s(_vm.perbaikan ? _vm.perbaikan.tanggal : null))]), _c('td', [_vm._v("Keterangan")]), _c('th', [_vm._v(_vm._s(_vm.perbaikan ? _vm.perbaikan.keterangan : null))])]), _c('tr', [_c('td', [_vm._v("Sudah disetujui GM?")]), _c('th', [_c('b-badge', {
    attrs: {
      "variant": _vm.perbaikan && _vm.perbaikan.gm_approve == 1 ? 'light-success' : 'light-danger'
    }
  }, [_vm._v(_vm._s(_vm.statusApproveGm))])], 1), _c('td', [_vm._v("Sudah disetujui Finance?")]), _c('th', [_c('b-badge', {
    attrs: {
      "variant": _vm.perbaikan && _vm.perbaikan.finance_approve != 0 ? 'light-success' : 'light-danger'
    }
  }, [_vm._v(_vm._s(_vm.statusApproveFinance))])], 1)]), _c('tr', [_c('td', [_vm._v("Pembuat Ajuan")]), _c('td', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.infoCreator)
    }
  })])])])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('h2', {
    staticClass: "mb-1"
  }, [_vm._v("Rincian Barang "), _vm.rincians.length > 0 ? _c('span', [_vm._v("(" + _vm._s(_vm.rincians.length) + ")")]) : _vm._e()]), _c('div', [_vm.selectedRincian.length > 0 ? _c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.completeRepair($event);
      }
    }
  }, [_vm._v("Tandai Selesai Diperbaiki (" + _vm._s(_vm.selectedRincian.length) + ")")]) : _vm._e()], 1)]), _c('b-card', [_vm.allowCreate() && _vm.perbaikan && _vm.perbaikan.gm_approve == 0 && _vm.perbaikan.finance_approve == 0 ? _c('b-button', {
    staticClass: "mb-2",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.chooseBarang($event);
      }
    }
  }, [_vm._v("Tambah Barang")]) : _vm._e(), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Filter",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Type to Search"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1)], 1), _c('b-table', {
    staticClass: "mb-2",
    attrs: {
      "striped": "",
      "responsive": "",
      "hover": "",
      "items": _vm.rincians,
      "fields": _vm.fields
    },
    scopedSlots: _vm._u([{
      key: "head(checkbox)",
      fn: function fn() {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.allChecked,
            callback: function callback($$v) {
              _vm.allChecked = $$v;
            },
            expression: "allChecked"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.status == 0 ? _c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.selectedRincian,
            callback: function callback($$v) {
              _vm.selectedRincian = $$v;
            },
            expression: "selectedRincian"
          }
        }) : _c('feather-icon', {
          staticClass: "text-success",
          attrs: {
            "icon": "CheckIcon"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + " ")];
      }
    }, {
      key: "cell(nama)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.penyimpanan && item.penyimpanan.asset ? _c('span', [_vm._v(" " + _vm._s(item.penyimpanan.asset.nama) + " ")]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Aset tidak ditemukan")])];
      }
    }, {
      key: "cell(satuan)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.jumlah) + " " + _vm._s(item.penyimpanan && item.penyimpanan.asset && item.penyimpanan.asset.satuan ? item.penyimpanan.asset.satuan.satuan : '-') + " ")];
      }
    }, {
      key: "cell(biaya)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" Rp " + _vm._s(_vm.formatRupiah(parseInt(item.biaya))) + " ")];
      }
    }, {
      key: "cell(keterangan)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('b-form-input', {
          model: {
            value: item.keterangan,
            callback: function callback($$v) {
              _vm.$set(item, "keterangan", $$v);
            },
            expression: "item.keterangan"
          }
        })];
      }
    }, {
      key: "cell(status)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('b-badge', {
          attrs: {
            "variant": item.status == 0 ? "light-danger" : 'light-success'
          }
        }, [_vm._v(" " + _vm._s(item.status == 0 ? "Dalam Perbaikan" : 'Sudah Diperbaiki') + " ")])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref7) {
        var index = _ref7.index;
        return [_vm.allowDelete() && _vm.perbaikan.gm_approve == 0 && _vm.perbaikan.finance_approve == 0 ? _c('feather-icon', {
          staticClass: "cursor-pointer text-danger",
          attrs: {
            "icon": "MinusCircleIcon",
            "size": "24"
          },
          on: {
            "click": function click($event) {
              return _vm.remove(index);
            }
          }
        }) : _vm._e()];
      }
    }])
  })], 1)], 1), _vm.allowCreate() && _vm.perbaikan && _vm.perbaikan.gm_approve == 0 && _vm.perbaikan.finance_approve == 0 ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "disabled": _vm.rincians.length < 1,
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v("Simpan")])], 1)]) : _vm._e(), _vm.canPay || _vm.canApprove ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', [_vm._v(" Total "), _c('strong', {
    staticClass: "text-danger"
  }, [_vm._v("Rp " + _vm._s(_vm.formatRupiah(_vm.totalPerbaikan)))])])]) : _vm._e()], 1), _c('form-akun', {
    on: {
      "onSubmit": _vm.submitAkun
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }